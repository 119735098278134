import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

const TwoImageBoxSection = styled.div`
  margin-top: 50px;
  position: relative;
  flex: 1;
  width: 100%;

  &:before {
    content: "";
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: 38px solid ${props => props.theme.colors.site.primaryLight};
    background-color: ${props => props.theme.colors.site.primaryDark};
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    margin-top: 157px;
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    margin-top: 250px;
  }
`

const ContentContainer = styled.div`
  position: relative;
  max-width: 1260px;
  margin-inline: auto;
  padding-inline: 28px;
`

const Box = styled.div`
  border-radius: 50px 0 50px 0;
  border: 4px solid ${props => props.theme.colors.site.secondaryLight};
  overflow: hidden;
  background: ${props => props.theme.colors.site.secondaryLight};
  transform: translateZ(0); // Fix border-radius issue

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;
  }
`

const TwoImageBox = ({ content }) => {
  return (
    <TwoImageBoxSection>
      <ContentContainer>
        <Box>
          <GatsbyImage
            image={
              content?.twoImageBox?.left_image_path?.childImageSharp
                ?.gatsbyImageData
            }
            alt=""
          />
          <GatsbyImage
            image={
              content?.twoImageBox?.right_image_path?.childImageSharp
                ?.gatsbyImageData
            }
            alt=""
          />
        </Box>
      </ContentContainer>
    </TwoImageBoxSection>
  )
}

export default TwoImageBox
