import React from "react"
import styled from "styled-components"
import P from "../../atoms/P"
import HeaderText from "../../atoms/HeaderText"
import Button from "../../atoms/Button"

export const AdvertisementAboutContainer = styled.div`
  margin: 68px auto 68px auto;

  //tablet
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    margin: 80px auto 96px auto;
  }
  //desktop
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    margin: 131.5px auto 111.5px auto;
  }
`
const ParagraphWrapper = styled.div`
  margin: 16px 28px 44px 28px;

  text-align: center;
  p {
    font-size: 16px;
    line-height: 24px;
  }

  //tablet
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    margin: 24px auto 56px auto;
    width: 504px;
    p {
      font-size: 18px;
      line-height: 28px;
    }
  }
  //desktop
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    margin: 40px auto 56px auto;
    width: 673px;
  }
`

const HeaderWrapper = styled.div`
  margin: 0 28px;
  text-align: center;
  p {
    font-size: 32px;
    line-height: 40px;
  }

  //tablet
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    margin: 24px auto 56px auto;
    width: 504px;
    p {
      font-size: 44px;
    }
  }
  //desktop
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    p {
      font-size: 44px;
    }
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
`

const AdvertisementAbout = ({ content }) => {
  return (
    <AdvertisementAboutContainer>
      <HeaderWrapper>
        <HeaderText color="text">{content.contact.header}</HeaderText>
      </HeaderWrapper>
      <ParagraphWrapper>
        <P color="text">{content.contact.text}</P>
      </ParagraphWrapper>
      <ButtonWrapper>
        <Button to="https://norbert-health.breezy.hr/">
          {content.contact.button_text}
        </Button>
      </ButtonWrapper>
    </AdvertisementAboutContainer>
  )
}

export default AdvertisementAbout
