import React from "react"
import styled from "styled-components"

export const GradientBackgroundWrapper = styled.div`
  //desktop
  position: relative;
  //mobile

  width: 100%;

  background: linear-gradient(
    146.51deg,
    #f1f2f2 40.2%,
    #eef1f2 54.48%,
    #e6f0f0 62.82%,
    #d8edee 69.96%,
    #c3e9eb 75.91%,
    #a9e3e7 81.86%,
    #89dde1 87.22%,
    #62d5db 91.98%,
    #37ccd4 96.74%,
    #15c5cf 99.72%
  );

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktop}px) {
    width: 100%;

    background: linear-gradient(
      146.51deg,
      #f1f2f2 40.2%,
      #eef1f2 54.48%,
      #e6f0f0 62.82%,
      #d8edee 69.96%,
      #c3e9eb 75.91%,
      #a9e3e7 81.86%,
      #89dde1 87.22%,
      #62d5db 91.98%,
      #37ccd4 96.74%,
      #15c5cf 99.72%
    );
  }
  //desktopSmall
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    width: 100%;

    background: linear-gradient(
      146.51deg,
      #f1f2f2 40.2%,
      #eef1f2 54.48%,
      #e6f0f0 62.82%,
      #d8edee 69.96%,
      #c3e9eb 75.91%,
      #a9e3e7 81.86%,
      #89dde1 87.22%,
      #62d5db 91.98%,
      #37ccd4 96.74%,
      #15c5cf 99.72%
    );
  }

  //tablet
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    width: 100%;

    background: linear-gradient(
      146.51deg,
      #f1f2f2 40.2%,
      #eef1f2 54.48%,
      #e6f0f0 62.82%,
      #d8edee 69.96%,
      #c3e9eb 75.91%,
      #a9e3e7 81.86%,
      #89dde1 87.22%,
      #62d5db 91.98%,
      #37ccd4 96.74%,
      #15c5cf 99.72%
    );
  }
`
const LeftEffect = styled.div`
  position: absolute;
  left: 0;
  width: 50%;
  height: 40px;
  background: red;
`

const RightEffect = styled.div`
  position: absolute;
  left: 50%;
  width: 50%;
  height: 40px;

  background: red;
`

const GradientTopBar = styled.div`
  width: 100%;
  height: 38px;
  background: ${props => props.theme.colors.site.secondaryLight};
  //mobile
  @media only screen and (max-width: ${props =>
      props.theme.screenBreakpoints.tablet}px) {
    height: 28px;
  }
`

const GradientBackground = props => {
  return (
    <GradientBackgroundWrapper>
      {props.topBar && <GradientTopBar />}
      {props.visualEffect && (
        <>
          <LeftEffect>sd </LeftEffect>
          <RightEffect> xd </RightEffect>
        </>
      )}
      <>{props.children}</>
    </GradientBackgroundWrapper>
  )
}

export default GradientBackground
