import React, { useState, useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import P from "../../atoms/P"
import HeaderText from "../../atoms/HeaderText"
import useMediaType from "../../hooks/useMediaType"

import styled from "styled-components"

export const BannerHistoryContainer = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
`
const HistoryWrapper = styled.div`
  margin-left: 28px;
  margin-right: 25px;
  margin-top: 59px;
  p {
    &:first-of-type {
      font-family: "Graphik Web Regular";
    }
  }
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    margin-left: auto;
    margin-right: auto;
    margin-top: 70px;
    max-width: 504px;
  }
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    position: relative;
    z-index: 0;
    margin-left: 29.02777777777778%;
    margin-right: 77px;
    margin-top: 84px;
    max-width: 728px;

    p {
      &:first-of-type {
        font-size: 22px;
      }
    }
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktop}px) {
    margin: 0 auto;
    // padding-left: 351px;
    // padding-right: 140px;
    max-width: 750px;
    margin-top: 97px;
  }
`
const HeaderWrapper = styled.div`
  text-align: left;
  margin-left: 28px;
  margin-right: 25px;
  margin-top: 94px;
  line-height: 32px;
  p {
    line-height: 32px;
  }

  @media only screen and (min-width: ${props =>
    props.theme.screenBreakpoints.tabletMin}px) {
    margin-left: 77px;
    margin-right: auto;
    margin-top: 70px;
    width: 592px;
    p {
      font-size: 32px;
      line-height: 40px;
    }
  }
  @media only screen and (min-width: ${props =>
    props.theme.screenBreakpoints.desktopSmallMin}px) {
    margin-left: auto;
    margin-right: auto;
    margin-top: 113px;
    max-width: 1260px;
    width: auto;
    padding-inline: 28px;

    p {
      font-size: 48px;
      line-height: 56px;
      max-width: 1070px;
    }
`

const Picture = styled.div`
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    max-width: 100%;
    margin-inline: auto;
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    // position: absolute;
    position: unset;
    top: 215px;
    left: calc((100vw - 1204px) / 2 - 175px);
    z-index: -1;
    margin: auto;
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktop}px) {
    left: calc((100vw - 1204px) / 2 - 200px);
  }
`

const BannerHistory = ({ content }) => {
  const [h, setH] = useState(1)
  const [p, setP] = useState(5)
  const mediaType = useMediaType()
  const sizeHandlerH = media => {
    switch (media) {
      case "desktop":
        return 5
      case "desktopSmall":
        return 5
      case "tablet":
        return 3
      case "mobile":
        return 5
      default:
        return 5
    }
  }

  const sizeHandler = media => {
    switch (media) {
      case "desktop":
        return "regular"
      case "desktopSmall":
        return "regular"
      case "tablet":
        return "regular"
      case "mobile":
        return "medium"
      default:
        return "medium"
    }
  }
  useEffect(() => {
    setP(sizeHandler(mediaType))
    setH(sizeHandlerH(mediaType))
  }, [mediaType])

  return (
    <BannerHistoryContainer className="bannerabouttext">
      <HeaderWrapper>
        <HeaderText size={h} color="text">
          {content.banner.text}
        </HeaderText>
      </HeaderWrapper>
      <HistoryWrapper>
        <P size={p} color="text">
          {content.history.paragraph_1}
        </P>
        <P size={p} color="text">
          {content.history.paragraph_2}
        </P>
        <P size={p} color="text">
          {content.history.paragraph_3}
        </P>
      </HistoryWrapper>
      <Picture>
        <GatsbyImage
          image={content?.banner?.image_path?.childImageSharp?.gatsbyImageData}
          loading={"eager"}
          alt=""
        />
      </Picture>
    </BannerHistoryContainer>
  )
}

export default BannerHistory
