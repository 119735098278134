import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import BannerHistory from "../components/organisms/BannerHistory"
import TwoImageBox from "../components/organisms/TwoImageBox"
import PeopleSection from "../components/organisms/PeopleSection"
import AdvertisementAbout from "../components/organisms/AdvertisementAbout"
import GradientBackground from "../components/atoms/GradientBackground"
import Layout from "../components/Layout"
import Seo from "../components/seo"

const About = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { type: { eq: "about_page" } } }
        ) {
          edges {
            node {
              frontmatter {
                banner {
                  header
                  text
                  image_path {
                    childImageSharp {
                      gatsbyImageData(placeholder: NONE)
                    }
                  }
                }
                history {
                  paragraph_1
                  paragraph_2
                }
                twoImageBox {
                  left_image_path {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                  right_image_path {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                peopleSections: peopleGroup {
                  header
                  items: people {
                    name
                    type
                    linkedinUrl
                    image_path {
                      childImageSharp {
                        gatsbyImageData(width: 151, height: 190)
                      }
                    }
                  }
                }
                contact {
                  header
                  text
                  button_text
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Seo title="About" />
      {allMarkdownRemark.edges.map((item, i) => {
        let content = item.node.frontmatter

        return (
          <React.Fragment key={i}>
            <BannerHistory content={content} />
            {/* <TwoImageBox content={content} /> */}
            <div style={{ width: "100%" }}>
              {content?.peopleSections?.map(section => (
                <PeopleSection
                  key={section.title}
                  header={section.header}
                  items={section.items}
                />
              ))}
            </div>
            <GradientBackground>
              <AdvertisementAbout content={content} />
            </GradientBackground>
          </React.Fragment>
        )
      })}
    </Layout>
  )
}

export default About
