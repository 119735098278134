import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

import HeaderText from "../../atoms/HeaderText"

import Linkedin from "../../../assets/icons/linkedin.svg"

export const PeopleSectionContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.site.primaryDark};
  padding-top: 72px;

  &:last-child {
    padding-bottom: 100px;
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    &:first-child {
      padding-top: 148px;
    }
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    &:last-child {
      padding-bottom: 167px;
    }
  }
`

const TopTextWrapper = styled.div`
  margin-bottom: 56px;
  padding-top: 12px;
  border-top: 1px solid ${props => props.theme.colors.site.secondaryDark};
  line-height: 1.25;
  letter-spacing: 0.2px;

  #top-header-text {
    font-size: 28px;
    line-height: 20px;
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    #top-header-text {
      font-size: 32px;
    }
  }
`

const PeopleInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px;

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    grid-template-columns: repeat(3, 151px);
    justify-content: space-between;
    gap: 48px 72px;
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    grid-template-columns: repeat(4, 151px);
    justify-content: space-between;
    gap: 48px 72px;
  }
`

const PeopleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  position: relative;
  padding-bottom: 40px;

  p {
    margin: 0;
  }
`
const PeopleImageWrapper = styled.div`
  max-width: 150px;
  width: 100%;
  aspect-ratio: 151/190;
  border-top-left-radius: 50px;
  border-top-right-radius: 4px;
  position: relative;
  overflow: hidden;
  margin-bottom: 24px;
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 138.75%
  );
  transform: translateZ(0); // Fix border-radius issue

  img {
    top: -1px;
    left: -1px !important;
    width: calc(100% + 1px) !important;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    border-bottom: 9px solid ${props => props.theme.colors.site.secondaryDark};
  }
`
const PeopleNameWrapper = styled.div`
  p {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
  }

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    p {
      font-size: 20px;
    }
  }
`
const PeopleDescriptionWrapper = styled.div`
  margin-top: 8px;
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: 0.2px;
`

const PeopleLink = styled.a`
  margin-top: 16px;
  position: absolute;
  bottom: 0;

  svg {
    width: 23px;
    fill: white;
  }

  &:hover {
    svg {
      fill: ${props => props.theme.colors.site.secondaryLight};
    }
  }
`

const ContentContainer = styled.div`
  max-width: 1070px;
  margin-inline: auto;
  padding-inline: 28px;
`

const PeopleSection = ({ header, items }) => {
  return (
    <PeopleSectionContainer>
      <ContentContainer>
        <TopTextWrapper id={header.toLowerCase()}>
          <HeaderText id="top-header-text">{header}</HeaderText>
        </TopTextWrapper>
        <PeopleInfoWrapper>
          {items?.map(person => (
            <PeopleWrapper key={person.name}>
              <PeopleImageWrapper>
                <GatsbyImage
                  image={person?.image_path?.childImageSharp?.gatsbyImageData}
                  alt={person.name}
                />
              </PeopleImageWrapper>

              <PeopleNameWrapper>
                <HeaderText color="white">{person.name}</HeaderText>
              </PeopleNameWrapper>

              <PeopleDescriptionWrapper>{person.type}</PeopleDescriptionWrapper>

              {person.linkedinUrl && (
                <PeopleLink
                  href={person.linkedinUrl}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Linkedin />
                </PeopleLink>
              )}
            </PeopleWrapper>
          ))}
        </PeopleInfoWrapper>
      </ContentContainer>
    </PeopleSectionContainer>
  )
}

export default PeopleSection
